.card-management-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.card-management-popup-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    overflow-y: auto;
}

.card-management-popup .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.card-management-popup .input-container label {
    margin-bottom: 5px;
}

.card-management-popup .input-container input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    align-self: flex-end;
}

.card-management-popup .button-container {
    display: flex;
    justify-content: space-between;
}

.card-management-popup-content-field {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.card-management-popup-content-field label {
    width: 150px;
    text-align: right;
    margin-right: 10px;
}

.card-management-popup-content-field input {
    width: 100%;
}

.card-mgt-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.card-mgt-button-container button {
    flex: 1 1;
    margin: 0 5px;
    color: white;
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.card-mgt-button-container button.close-button,
.card-mgt-button-container button.save-button {
    background-color: #1E2029;
    color: white;
}
.card-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.card-popup-content {
  position: relative;
  background-color: #12141d;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #696060;
}

.card-btn-close-button {
  font-size: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

.card-btn-claim-button,
.card-btn-edit-button,
.card-btn-delete-button {
  background-color: #45a049;
  color: white;
  cursor: pointer;
}

.card-btn-delete-button {
  background-color: #d32f2f;
}

.card-btn-edit-button,
.card-btn-delete-button,
.card-btn-claim-button {
  margin: 0 5px;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  cursor: pointer;
}

.card-btn-card-button-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.card-details-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 2px solid #ccc;
  padding: 10px;
  color: white;
}

.cards-category-container {
  background-color: #1e2029;
  color: white !important;
  padding: 10px;
  border-radius: 10px;
  align-self: center;
  color: black;
}

.card-details.card-details-content h2 {
  display: flex;
}

.card-details.content-container {
  padding-left: 10%;
  color: white;
}

.cards-details-h2 {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}
.cards-details-span {
  align-self: center;
  padding-left: 5px;
}
.card-btn {
  display: flex;
  justify-content: space-between;
}
.card-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lemon-overlay {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  background-color: transparent;
}

.lemon-overlay-content {
  flex: 1;
}

.card-claim-tooltip {
  color: white;
  align-self: center;
  border: 1px solid #4e4e4e;
  padding-right: 10px;
  margin-top: 10px;
  font-size: 18px;
}

.cards-import-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #272a34;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    z-index: 9999;
}

.cards-import-popup .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.start-index-container-input-container label {
    margin-bottom: 5px;
    color: white;
}

.start-index-container-input-container input {
    padding: 5px;
    margin-bottom: 5px;
    width: 25%;
    margin-left: 10px;
    border: none;
    background-color: #1E2029;
    color: white;
    
}
.cards-import-popup .input-container label {
    margin-bottom: 5px;
}

.cards-import-popup .input-container input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.cards-import-popup .button-container {
    display: flex;
    justify-content: space-between;
}

.cards-import-popup button {
    flex: 1 1;
    margin: 0 5px;
    color: white;
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cards-import-popup button.upload-button {
    background-color: #1E2029;
    color: white;
}

.cards-import-popup button.upload-button:hover {
    background-color: #45a049;
}

.cards-import-popup button.close-button {
    background-color: #1E2029;
    color: white;
}

.cards-import-popup button.close-button:hover {
    background-color: #d32f2f;
}

.cards-import-file.input-container {
    border: "1px solid #ccc";
    padding: "20px";
    margin-bottom: "20px";
    color: white;
}

.cards-import-file.input-container-drag-drop {
    border: 2px dashed #ccc;
    padding: 25px;
    text-align: center;
    color: #888;
    cursor: pointer;
}

.cards-import-file.input-container-drag-drop.active {
    background-color: #f7f7f7;
}

/* Progress bar styles */
.progress-container {
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50;
    width: 0;
}

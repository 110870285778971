/* Any changes here affects all the components which reference this css */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: "transparent" ;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9990;
  }
  
  .loading-spinner {
    border: 6px solid #f3f3f3;
    border-top: 6px solid black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
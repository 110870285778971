.navbar {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #0E1017;
  color: white;
  padding: 1.8rem 1rem;
  z-index: 9997;
  position: fixed;
  top: 0;
  /* width: 100%; */
  height: 7%;
}
.add-button {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  display: inline-block;
  visibility: hidden;
}

.add-button:hover .tooltip {
  visibility: visible;
}

.logout-button {
  padding: 0.3rem 0.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #0056b3;
}

.add-button {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.add-button:before,
.add-button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 3px;
  background-color: white;
}

.add-button:before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.add-button:after {
  transform: translate(-50%, -50%);
}

.button-container {
  display: flex;
  align-items: center;
  margin-right: 2%;
}

.Dropdown-root {
  width: 250px;
}

.Dropdown-menu {
  background-color:  #35373f !important;
  border: none !important;
  max-height: unset !important;
}

.Dropdown-option.is-selected {
  background-color:#35373f !important;
  color: white !important;
}

.Dropdown-option {
  color: white !important;
  border: none !important;
}

.Dropdown-option:hover {
  color: black !important;
}
.Dropdown-control {
  background-color: #35373f !important;
  color: white !important;
  border: none !important;
}
.signup-container {
  margin: 50px auto;
  padding: 20px;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.signup-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.signup-container input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.signup-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin: 10px 0;
}

.signup-container button:hover {
  background-color: #0056b3;
}

.signup-input-container {
  display: grid;
}

.sidebar {
  background-color: #1E2029;
  padding: 1rem;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: center;
  color: #555;
  font-size: 18px;
  width: 100%;
  margin: auto;
}

.no-data-icon {
  font-size: 50px;
  margin-bottom: 10px;
  color: #999;
}